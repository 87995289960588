import { IGlobalEnvironment } from "@interfaces/iEnvironment";

export const globalEnvironment: IGlobalEnvironment = {
	buildDate:'2025-04-09T15:00:12.507Z',
	youtubeCookieNames: ['Youtube Cookies (ytimg)', 'YouTube Video', 'YouTube'],
	treePath: "/live-catalog-public/catalog/{key}/cache/tree_{encoding}.json",
	catalogLogoPath: "/live-catalog-public/catalog/{key}/logo/{size}/{key}.{format}",
	prefetchCatalogPath: "/api/v3/CatalogView/catalogKey/{key}",
	prefetchCatalogDetailsPath: "/api/v3/CatalogView/catalogItemGuid/{guid}/details",
	prefetchWatchlistInforamtionPath: "/api/v3/WatchList/information",
	prefetchCatalogItemMetaPath: "/api/v3/CatalogView/catalogItem/{guid}/meta",
	prefetchCatalogInfoPath: "/api/v3/CatalogView/catalogKey/{key}/meta",
	trackIntervalInSec: 5
}